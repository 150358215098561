var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6388f43df40c69a001f221f92d23548926e29d26"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    "ResizeObserver loop limit exceeded",
  ],
  normalizeDepth: 5,
  enabled: process.env.NODE_ENV !== "development",
  beforeSend: (event) => {
    if (
      event.request?.query_string?.includes("builder.user.role.id=developer")
    ) {
      return null;
    }
    return event;
  },
});
